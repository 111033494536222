import _inject from "@stylexjs/stylex/lib/stylex-inject";
import * as stylex from '@stylexjs/stylex';
_inject(".xg6iff7{min-height:100vh}", 4000);
_inject(".x1xbu32o{background:linear-gradient(145deg,rgb(12,9,16) 0,rgba(30,22,40,1) 44%,rgba(15,78,91,1) 100%)}", 1000);
_inject(".x1uz70x1{padding:1rem}", 1000);
_inject(".x9f619{box-sizing:border-box}", 3000);
_inject(".x1awj2ng{color:white}", 3000);
_inject(".x78zum5{display:flex}", 3000);
_inject(".xdt5ytf{flex-direction:column}", 3000);
_inject(".xh8yej3{width:100%}", 4000);
_inject(".x6s0dn4{align-items:center}", 3000);
_inject(".x8fetqu{gap:1rem}", 2000);
_inject(".xl56j7k{justify-content:center}", 3000);
_inject(".x1n7r0mb{max-width:50rem}", 4000);
_inject(".x1bpp3o7{margin:auto}", 1000);
_inject(".x1n2onr6{position:relative}", 3000);
_inject(".x3wclze{color:#d0d7dc}", 3000);
_inject(".xieb3on{margin-bottom:20px}", 4000);
_inject(".xo5v014{line-height:1}", 3000);
_inject(".x1oysuqx{width:200px}", 4000);
_inject(".x1m3v4wt{height:200px}", 4000);
_inject(".xorixrz{border-radius:100%}", 2000);
_inject(".x11jljnd{background-color:#353c41}", 3000);
_inject(".xnrpozw{box-shadow:0 10px 13px -7px #000000,5px 5px 15px 5px rgba(97,255,124,0)}", 3000, ".xnrpozw{box-shadow:0 10px 13px -7px #000000, -5px 5px 15px 5px rgba(97,255,124,0)}");
_inject(".x12yqigk{color:#61FF7C}", 3000);
_inject(".x1vuuof{font-size:60px}", 3000);
_inject(".xk50ysn{font-weight:500}", 3000);
_inject(".xtvhhri{text-transform:uppercase}", 3000);
_inject(".x1ewwscv{font-size:26px}", 3000);
_inject(".xo1l8bm{font-weight:400}", 3000);
_inject(".xscf7lr{font-size:70px}", 3000);
_inject(".x1s688f{font-weight:600}", 3000);
_inject(".x1sy10c2{margin-top:20px}", 4000);
_inject(".xosj86m{font-size:18px}", 3000);
_inject(".xqyf9gi{width:250px}", 4000);
_inject(".x10l6tqk{position:absolute}", 3000);
_inject(".xm5vxui{top:110px}", 4000);
_inject(".x1n0khkq{color:rebeccapurple}", 3000);
_inject(".xu96u03{left:0}", 4000);
_inject(".x1oqrxb4{padding-left:2rem}", 4000);
_inject(".x1rg5ohu{display:inline-block}", 3000);
_inject(".x52dz5p{padding-right:1rem}", 4000);
_inject(".x1xxd1zf{color:#F6AE2D}", 3000);
_inject(".x1gwx9ft{transition:color .2s}", 2000);
_inject(".xzv4tpr:hover{color:#B07507}", 3130);
_inject(".x188tqju{width:3rem}", 4000);
_inject(".x1pizb70{height:3rem}", 4000);
_inject(".x1dftt0s{filter:invert(1)}", 3000);
_inject(".x187nrv{min-width:340px}", 4000);
_inject(".xlpg0r2{width:30vw}", 4000);
_inject(".x1nqqiwj{width:22rem}", 4000);
_inject(".xpnphj7{max-width:calc(100% - 1rem)}", 4000);
_inject(".x3fmtp2{background-color:#A393BF}", 3000);
_inject(".xsfi3it{padding:2rem .5rem}", 1000);
_inject(".xx20hqb{border-radius:2rem}", 2000);
_inject(".x2b8uid{text-align:center}", 3000);
_inject(".x1mqxbix{color:black}", 3000);
_inject(".x1oza17f{border:1px solid #FFFCFF}", 1000);
_inject(".x1a02dak{flex-wrap:wrap}", 3000);
_inject(".xuk3077{align-items:flex-end}", 3000);
_inject(".x1qughib{justify-content:space-between}", 3000);
_inject(".x3wxoqf{padding:0 .5rem}", 1000);
_inject(".xekueh{width:4rem}", 4000);
_inject(".x1jfayfe{gap:.5}", 2000);
_inject(".x1a2a7pz{outline:none}", 2000);
_inject(".x1oqdnn1{margin:.5rem 0}", 1000);
_inject(".x5yr21d{height:100%}", 4000);
_inject(".xyemx9p{border-radius:16rem}", 2000);
_inject(".x10l298h{background:#FFFCFF}", 1000);
_inject(".x1dn4528{box-shadow:inset 0 -3px 1px rgba(0,0,0,.2),inset 0 3px 1px rgba(255,255,255,.9),0 0 rgba(0,0,0,.1),0 1px 2px rgba(0,0,0,.05),-1px 2px 2px rgba(0,0,0,.04737),-2px 3px 2px rgba(0,0,0,.04474),-3px 4px 2px rgba(0,0,0,.04211),-4px 5px 2px rgba(0,0,0,.03947),-5px 6px 2px rgba(0,0,0,.03684),-6px 7px 2px rgba(0,0,0,.03421),-7px 8px 2px rgba(0,0,0,.03158),-8px 9px 2px rgba(0,0,0,.02895),-9px 10px 2px rgba(0,0,0,.02632),-10px 11px 2px rgba(0,0,0,.02368),-11px 12px 2px rgba(0,0,0,.02105),-12px 13px 2px rgba(0,0,0,.01842),-13px 14px 2px rgba(0,0,0,.01579),-14px 15px 2px rgba(0,0,0,.01316),-15px 16px 2px rgba(0,0,0,.01053),-16px 17px 2px rgba(0,0,0,.00789),-17px 18px 2px rgba(0,0,0,.00526),-18px 19px 2px rgba(0,0,0,.00263),-19px 20px 2px transparent}", 3000, ".x1dn4528{box-shadow:inset 0 -3px 1px rgba(0,0,0,.2), inset 0 3px 1px rgba(255,255,255,.9), 0 0 rgba(0,0,0,.1), 0 1px 2px rgba(0,0,0,.05), 1px 2px 2px rgba(0,0,0,.04737), 2px 3px 2px rgba(0,0,0,.04474), 3px 4px 2px rgba(0,0,0,.04211), 4px 5px 2px rgba(0,0,0,.03947), 5px 6px 2px rgba(0,0,0,.03684), 6px 7px 2px rgba(0,0,0,.03421), 7px 8px 2px rgba(0,0,0,.03158), 8px 9px 2px rgba(0,0,0,.02895), 9px 10px 2px rgba(0,0,0,.02632), 10px 11px 2px rgba(0,0,0,.02368), 11px 12px 2px rgba(0,0,0,.02105), 12px 13px 2px rgba(0,0,0,.01842), 13px 14px 2px rgba(0,0,0,.01579), 14px 15px 2px rgba(0,0,0,.01316), 15px 16px 2px rgba(0,0,0,.01053), 16px 17px 2px rgba(0,0,0,.00789), 17px 18px 2px rgba(0,0,0,.00526), 18px 19px 2px rgba(0,0,0,.00263), 19px 20px 2px transparent}");
_inject(".x1nrll8i{left:50%}", 4000);
_inject(".x13vifvy{top:0}", 4000);
_inject(".xlxyqfn{height:50%}", 4000);
_inject(".xfo62xy{width:2px}", 4000);
_inject(".xitnhlw{transform:translateX(50%)}", 3000);
_inject(".xkx4m3a{background:rgb(12,9,16)}", 1000);
_inject(".xlshs6z{visibility:hidden}", 3000);
_inject(".xs5h3dt{width:2rem}", 4000);
_inject(".x1ypdohk{cursor:pointer}", 3000);
_inject(".xkcp37y{filter:none}", 3000);
_inject(".x1q2mzld{width:14rem}", 4000);
_inject(".x13y2g9h{margin:0 0 0 .5rem}", 1000);
_inject(".x12peec7{background-color:white}", 3000);
_inject(".xrazkaw{border:thin solid bluergb(12,9,16)}", 1000);
_inject(".x12oqio5{border-radius:4px}", 2000);
_inject(".xln7xf2{font:inherit}", 1000);
_inject(".x17mssa0{line-height:1.5rem}", 3000);
_inject(".xjhvfdx{padding:.2rem 3.3rem .2rem .8rem}", 1000);
_inject(".x1b8ysro{-webkit-box-sizing:border-box}", 3000);
_inject(".x1r560bz{-moz-box-sizing:border-box}", 3000);
_inject(".x1lugfcp{-webkit-appearance:none}", 3000);
_inject(".x12s6a5a{-moz-appearance:none}", 3000);
_inject(".xm4v1r9{background-image:linear-gradient(45deg,transparent 50%,rgb(12,9,16) 50%),linear-gradient(135deg,rgb(12,9,16) 50%,transparent 50%),linear-gradient(to right,skyblue,skyblue)}", 3000);
_inject(".x17msfsv{background-position:calc(100% - 1.25rem) calc(1rem - 2px),calc(100% - 1.25rem + 8px) calc(1rem - 2px),100% 0}", 2000);
_inject(".xt7na4j{background-size:8px 8px,8px 8px,2.5rem 2.5rem}", 3000);
_inject(".xiy17q3{background-repeat:no-repeat}", 3000);
_inject(".x11qc72a:focus{background-image:linear-gradient(45deg,white 50%,transparent 50%),linear-gradient(135deg,transparent 50%,white 50%),linear-gradient(to right,gray,gray)}", 3150);
_inject(".x1kghl9l:focus{background-position:calc(100% - 1.25rem + 8px) calc(1rem - 4px),calc(100% - 1.25rem) calc(1rem - 4px),100% 0}", 2150);
_inject(".xfuokm5:focus{border-color:grey}", 2150);
_inject(".xfpc920:focus{outline:0}", 2150);
_inject(".xwfjacg{background-color:rgb(12,9,16)}", 3000);
_inject(".x18o3ruo{background-image:none}", 3000);
_inject(".xki3578{padding:.2rem .8rem}", 1000);
_inject(".xt0e3qv{cursor:default}", 3000);
_inject(".xb3r6kr{overflow:hidden}", 2000);
_inject(".x116uinm{border-radius:.5rem}", 2000);
_inject(".x1w184yy{border:1px solid white}", 1000);
_inject(".x14rvwrp{width:600px}", 4000);
_inject(".x193iq5w{max-width:100%}", 4000);
_inject(".x18dl8mb{height:150px}", 4000);
_inject(".xysyzu8{overflow:auto}", 2000);
_inject(".x7gbtqy{padding:.5rem}", 1000);
_inject(".xifi7vn{filter:grayscale(1)}", 3000);
_inject(".x1bvjpef{text-decoration:underline}", 2000);
_inject(".xdzyupr{width:300px}", 4000);
_inject(".x1s85apg{display:none}", 3000);
_inject(".x1l1ennw{justify-content:space-around}", 3000);
_inject(".xtyb08i{background:rgb(15,78,91)}", 1000);
_inject(".x5zvi0u{padding:.3rem}", 1000);
_inject(".xabzvky{border-right:2px solid white}", 2000);
_inject(".x18wvu1z{transition:background .4s}", 2000);
_inject(".xahlotz:last-child{border-right:none}", 2054);
_inject(".x53wxy3:hover{background:rgb(12,9,16)}", 1130);
_inject(".x1o7uuvo:hover{opacity:1}", 3130);
_inject(".xbyyjgo{opacity:.5}", 3000);
_inject(".x1hc1fzr{opacity:1}", 3000);
export const styles = {
  app: {
    minHeight: "xg6iff7",
    background: "x1xbu32o",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    padding: "x1uz70x1",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    boxSizing: "x9f619",
    color: "x1awj2ng",
    $$css: true
  },
  ampWrapper: {
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "xh8yej3",
    alignItems: "x6s0dn4",
    gap: "x8fetqu",
    rowGap: null,
    columnGap: null,
    $$css: true
  },
  header: {
    display: "x78zum5",
    gap: "x8fetqu",
    rowGap: null,
    columnGap: null,
    justifyContent: "xl56j7k",
    $$css: true
  },
  about: {
    maxWidth: "x1n7r0mb",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  tuner: {
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    position: "x1n2onr6",
    color: "x3wclze",
    marginBottom: "xieb3on",
    lineHeight: "xo5v014",
    $$css: true
  },
  tunerResult: {
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    width: "x1oysuqx",
    height: "x1m3v4wt",
    borderRadius: "xorixrz",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    backgroundColor: "x11jljnd",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    boxShadow: "xnrpozw",
    color: "x3wclze",
    $$css: true
  },
  tuned: {
    color: "x12yqigk",
    $$css: true
  },
  noteName: {
    fontSize: "x1vuuof",
    fontWeight: "xk50ysn",
    textTransform: "xtvhhri",
    $$css: true
  },
  octave: {
    fontSize: "x1ewwscv",
    fontWeight: "xo1l8bm",
    textTransform: "xtvhhri",
    $$css: true
  },
  cents: {
    fontSize: "xscf7lr",
    fontWeight: "x1s688f",
    textTransform: "xtvhhri",
    $$css: true
  },
  frequency: {
    marginTop: "x1sy10c2",
    fontSize: "xosj86m",
    fontWeight: "xo1l8bm",
    $$css: true
  },
  rangeBar: {
    width: "xqyf9gi",
    height: "x1m3v4wt",
    position: "x10l6tqk",
    top: "xm5vxui",
    $$css: true
  },
  highlighted: {
    color: "x1n0khkq",
    $$css: true
  },
  credits: {
    width: "xh8yej3",
    position: "x1n2onr6",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    paddingLeft: "x1oqrxb4",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    boxSizing: "x9f619",
    $$css: true
  },
  socialLink: {
    display: "x1rg5ohu",
    paddingRight: "x52dz5p",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    $$css: true
  },
  link: {
    color: "x1xxd1zf",
    transition: "x1gwx9ft",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":hover_color": "xzv4tpr",
    $$css: true
  },
  social: {
    width: "x188tqju",
    height: "x1pizb70",
    filter: "x1dftt0s",
    $$css: true
  },
  inputDeviceWrapper: {
    minWidth: "x187nrv",
    width: "xlpg0r2",
    $$css: true
  },
  inputDevice: {
    width: "xh8yej3",
    $$css: true
  },
  amp: {
    width: "x1nqqiwj",
    maxWidth: "xpnphj7",
    backgroundColor: "x3fmtp2",
    padding: "xsfi3it",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderRadius: "xx20hqb",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    textAlign: "x2b8uid",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    gap: "x8fetqu",
    rowGap: null,
    columnGap: null,
    color: "x1mqxbix",
    border: "x1oza17f",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: null,
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  ampControls: {
    display: "x78zum5",
    flexWrap: "x1a02dak",
    alignItems: "xuk3077",
    justifyContent: "x1qughib",
    padding: "x3wxoqf",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  knobBase: {
    width: "xekueh",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    gap: "x1jfayfe",
    rowGap: null,
    columnGap: null,
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    $$css: true
  },
  knobHeadless: {
    position: "x1n2onr6",
    margin: "x1oqdnn1",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    width: "x188tqju",
    height: "x1pizb70",
    outline: "x1a2a7pz",
    outlineColor: null,
    outlineOffset: null,
    outlineStyle: null,
    outlineWidth: null,
    $$css: true
  },
  knobBaseThumbWrapper: {
    position: "x10l6tqk",
    height: "x5yr21d",
    width: "xh8yej3",
    borderRadius: "xyemx9p",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    background: "x10l298h",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    boxShadow: "x1dn4528",
    $$css: true
  },
  knobBaseThumbWrapper2: {
    position: "x10l6tqk",
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  knobBaseThumb: {
    position: "x10l6tqk",
    left: "x1nrll8i",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    height: "xlxyqfn",
    width: "xfo62xy",
    transform: "xitnhlw",
    background: "xkx4m3a",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    $$css: true
  },
  directoryInputWrapper: {
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  directoryHiddenInput: {
    visibility: "xlshs6z",
    position: "x10l6tqk",
    $$css: true
  },
  directoryButton: {
    width: "xs5h3dt",
    cursor: "x1ypdohk",
    filter: "x1dftt0s",
    $$css: true
  },
  directoryButtonDarkMode: {
    filter: "xkcp37y",
    $$css: true
  },
  directoryFileSelect: {
    cursor: "x1ypdohk",
    width: "x1q2mzld",
    margin: "x13y2g9h",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundColor: "x12peec7",
    border: "xrazkaw",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: null,
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    display: "x1rg5ohu",
    font: "xln7xf2",
    fontFamily: null,
    fontSize: null,
    fontStretch: null,
    fontStyle: null,
    fontVariant: null,
    fontVariantAlternates: null,
    fontVariantCaps: null,
    fontVariantEastAsian: null,
    fontVariantEmoji: null,
    fontVariantLigatures: null,
    fontVariantNumeric: null,
    fontVariantPosition: null,
    fontWeight: null,
    lineHeight: "x17mssa0",
    padding: "xjhvfdx",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    "-webkit-box-sizing": "x1b8ysro",
    "-moz-box-sizing": "x1r560bz",
    "box-sizing": "x9f619",
    "-webkit-appearance": "x1lugfcp",
    "-moz-appearance": "x12s6a5a",
    backgroundImage: "xm4v1r9",
    backgroundPosition: "x17msfsv",
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundSize: "xt7na4j",
    backgroundRepeat: "xiy17q3",
    ":focus_backgroundImage": "x11qc72a",
    ":focus_backgroundPosition": "x1kghl9l",
    ":focus_backgroundPositionX": null,
    ":focus_backgroundPositionY": null,
    ":focus_borderColor": "xfuokm5",
    ":focus_borderInlineColor": null,
    ":focus_borderInlineStartColor": null,
    ":focus_borderLeftColor": null,
    ":focus_borderInlineEndColor": null,
    ":focus_borderRightColor": null,
    ":focus_borderBlockColor": null,
    ":focus_borderTopColor": null,
    ":focus_borderBottomColor": null,
    ":focus_outline": "xfpc920",
    ":focus_outlineColor": null,
    ":focus_outlineOffset": null,
    ":focus_outlineStyle": null,
    ":focus_outlineWidth": null,
    $$css: true
  },
  directoryFileSelectDarkMode: {
    backgroundColor: "xwfjacg",
    color: "x1awj2ng",
    $$css: true
  },
  selectWithoutExpander: {
    backgroundImage: "x18o3ruo",
    padding: "xki3578",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  directoryFileSelectDisabled: {
    cursor: "xt0e3qv",
    $$css: true
  },
  fileTreeWrapper: {
    width: "xh8yej3",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    $$css: true
  },
  fileTree: {
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    borderRadius: "x116uinm",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    border: "x1w184yy",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: null,
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    width: "x14rvwrp",
    maxWidth: "x193iq5w",
    $$css: true
  },
  fileTreeContent: {
    height: "x18dl8mb",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    padding: "x7gbtqy",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    boxSizing: "x9f619",
    $$css: true
  },
  fileTreeLoading: {
    filter: "xifi7vn",
    $$css: true
  },
  selectedDirectory: {
    textDecoration: "x1bvjpef",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    $$css: true
  },
  audioMeter: {
    border: "x1w184yy",
    borderWidth: null,
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: null,
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: null,
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    display: "x78zum5",
    height: "x5yr21d",
    $$css: true
  },
  visualizer: {
    width: "xdzyupr",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  hiddenVisualizer: {
    display: "x1s85apg",
    $$css: true
  },
  postFxControls: {
    display: "x78zum5",
    flexWrap: "x1a02dak",
    justifyContent: "x1l1ennw",
    alignItems: "xuk3077",
    padding: "x3wxoqf",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    gap: "x8fetqu",
    rowGap: null,
    columnGap: null,
    $$css: true
  },
  controlsCategorySwitch: {
    display: "x78zum5",
    justifyContent: "xl56j7k",
    borderRadius: "x116uinm",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  controlsCategory: {
    background: "xtyb08i",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    cursor: "x1ypdohk",
    padding: "x5zvi0u",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    color: "x1awj2ng",
    borderRight: "xabzvky",
    borderRightWidth: null,
    borderInlineStartWidth: null,
    borderInlineEndWidth: null,
    borderRightStyle: null,
    borderInlineStartStyle: null,
    borderInlineEndStyle: null,
    borderRightColor: null,
    borderInlineStartColor: null,
    borderInlineEndColor: null,
    transition: "x18wvu1z",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":last-child_borderRight": "xahlotz",
    ":last-child_borderRightWidth": null,
    ":last-child_borderInlineStartWidth": null,
    ":last-child_borderInlineEndWidth": null,
    ":last-child_borderRightStyle": null,
    ":last-child_borderInlineStartStyle": null,
    ":last-child_borderInlineEndStyle": null,
    ":last-child_borderRightColor": null,
    ":last-child_borderInlineStartColor": null,
    ":last-child_borderInlineEndColor": null,
    ":hover_background": "x53wxy3",
    ":hover_backgroundAttachment": null,
    ":hover_backgroundClip": null,
    ":hover_backgroundColor": null,
    ":hover_backgroundImage": null,
    ":hover_backgroundOrigin": null,
    ":hover_backgroundPosition": null,
    ":hover_backgroundPositionX": null,
    ":hover_backgroundPositionY": null,
    ":hover_backgroundRepeat": null,
    ":hover_backgroundSize": null,
    ":hover_opacity": "x1o7uuvo",
    opacity: "xbyyjgo",
    $$css: true
  },
  controlsCategoryActive: {
    opacity: "x1hc1fzr",
    $$css: true
  },
  hide: {
    display: "x1s85apg",
    $$css: true
  }
};